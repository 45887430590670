function universalProduct(state = {}, action) {
  switch (action.type) {
    case "SET_UNIVERSAL_PRODUCT":
      return {
        ...action.payload,
      };

    default:
      return state;
  }
}

export default universalProduct;
