import React from "react";

import { countryData } from "./countries";
import LocationAutocomplete from ".";

const CountryAutocomplete = ({ ...props }) => (
  <LocationAutocomplete locations={countryData} {...props} />
);

export default CountryAutocomplete;
