import Typography from "@material-ui/core/Typography";

import Image from "src/components/Image";
import { useStyles } from "./styles";

export const Copyright = () => {
  const classes = useStyles();

  return (
    <div className={classes.logoAndCopyright}>
      <div>
        <a href="/">
          <Image
            src="/images/_next/logo-mark.svg"
            height="48"
            width="48"
            layout="fixed"
            alt="Much Better Adventures Logo"
            className={classes.logoMark}
          />
        </a>
      </div>
      <div className={classes.copyright}>
        <Typography variant="caption" align="center" className={classes.text}>
          &copy; Copyright Gentianes Solutions Ltd.
        </Typography>
        <Typography variant="caption" align="center" className={classes.text}>
          Registration Number 06916506 (England and Wales)
        </Typography>
      </div>
    </div>
  );
};
