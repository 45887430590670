import { memo } from "react";
import SalesPoint from "src/components/explore/SalesPoints/SalesPoint";

import { Grid } from "@material-ui/core";

const SalesPoints = ({ orientation, salesPoints }) => {
  return (
    <Grid container spacing={4}>
      {salesPoints.map((salesPoint) => (
        <Grid key={salesPoint.title} item xs={12} sm={6} md={3}>
          <SalesPoint orientation={orientation} salesPoint={salesPoint} />
        </Grid>
      ))}
    </Grid>
  );
};

export default memo(SalesPoints);
