import FacebookIcon from "mdi-material-ui/Facebook";
import InstagramIcon from "mdi-material-ui/Instagram";
import TwitterIcon from "mdi-material-ui/Twitter";

import { IconButton } from "@material-ui/core";

import { useStyles } from "./styles";

export const SocialButtons = () => {
  const classes = useStyles();

  return (
    <>
      <IconButton
        className={classes.socialButton}
        href="https://www.facebook.com/muchbetteradventures/"
      >
        <FacebookIcon htmlColor="white" />
      </IconButton>
      <IconButton
        className={classes.socialButton}
        href="https://twitter.com/muchbetteradven"
      >
        <TwitterIcon htmlColor="white" />
      </IconButton>
      <IconButton
        className={classes.socialButton}
        href="https://www.instagram.com/muchbetteradventures/"
      >
        <InstagramIcon htmlColor="white" />
      </IconButton>
    </>
  );
};
