import Image from "src/components/Image";

import { Typography } from "@material-ui/core";

import { useStyles } from "./styles";

export const Bcorp = ({ title, body, ctaText, ctaHref, logoSrc }) => {
  const classes = useStyles();
  return (
    <div className={classes.bcorp}>
      <Typography variant="h4">{title}</Typography>

      <div className={classes.contentLayout}>
        <div className={classes.imagePosition}>
          <Image
            src={logoSrc}
            height="146"
            width="100%"
            objectFit="contain"
            alt="Bcorp Logo"
          />
        </div>

        <div className={classes.body}>
          <Typography variant="caption" component="div">
            <>
              <div>{body}</div>
              {!!ctaText && !!ctaHref && (
                <div className={classes.cta}>
                  <a href={ctaHref} target="_blank" rel="noreferrer">
                    {ctaText}
                  </a>
                </div>
              )}
            </>
          </Typography>
        </div>
      </div>
    </div>
  );
};
