import { useDefault } from "./useDefault";
import { usePosthog } from "./usePosthog";

const getFlagSource = (flagSourceName) => {
  switch (flagSourceName) {
    case "usePosthog":
      return usePosthog;

    case "useDefault":
      return useDefault;

    default:
      return useDefault;
  }
};

export { getFlagSource };
