import { createSelector } from "reselect";

export const getShortlistState = (state) => state.shortlist;

export const getShortlistedProducts = createSelector(
  getShortlistState,
  (shortlist) => shortlist.products || []
);

export const getShortlistedProductIds = createSelector(
  getShortlistedProducts,
  (products) => products.map((product) => product.product)
);

export const getProductIdFromProps = (state, props) => props.productId;

export const makeGetIsProductShortlisted = () =>
  createSelector(
    [getShortlistedProductIds, getProductIdFromProps],
    (productIds, productId) => {
      return products.some((id) => id === productId);
    }
  );
