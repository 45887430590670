import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  footerList: {
    listStyleType: "none",
    padding: 0,
  },
  footerListHeader: {
    color: "#8e8e8e",
    textDecoration: "none",
  },
  footerLink: {
    textDecoration: "none",
    color: "white",

    "&:hover": {
      textDecoration: "underline",
    }
  },
  menuItemBlock: {
    paddingRight: `${theme.spacing(1)}px`,
  }
}));
