import Image from "src/components/Image";
import FeatureFlagProvider, {
  FLAGS_FROM_ENV,
  getFlagSource,
} from "src/context/featureFlags";

import { ThemeProvider as MuiV4ThemeProvider } from "@material-ui/styles";
import theme from "@muchbetteradventures/mui-theme";
import {
  MBAThemeProvider,
  primaryTheme,
} from "@muchbetteradventures/styleguide";

import {
  FEATURE_HIGHLIGHT_DEPARTURES_MODE_TAB,
  FeatureHighlightsContextProvider,
  featureHighlightsStoreLocal,
} from "./featureHighlights";

const featureFlagSource = getFlagSource(
  process.env.NEXT_PUBLIC_FEATURE_FLAG_DEFAULT_FLAG_SOURCE
);

const AppProviders = ({ children }) => {
  return (
    <MuiV4ThemeProvider theme={theme}>
      <MBAThemeProvider theme={primaryTheme} imageComponent={Image}>
        <FeatureFlagProvider
          defaultFlags={FLAGS_FROM_ENV}
          flagSource={featureFlagSource}
        >
          <FeatureHighlightsContextProvider
            store={featureHighlightsStoreLocal}
            initialState={featureHighlightsStoreLocal.getFromStore([
              FEATURE_HIGHLIGHT_DEPARTURES_MODE_TAB,
            ])}
          >
            {children}
          </FeatureHighlightsContextProvider>
        </FeatureFlagProvider>
      </MBAThemeProvider>
    </MuiV4ThemeProvider>
  );
};

export default AppProviders;
