import { useEffect, useState } from "react";

export const useDefault = (defaultFlags) => {
  const [initialized, setInitialized] = useState(false);
  const [flags, setFlags] = useState(defaultFlags);

  useEffect(() => {
    window.__flags__ = {
      currentFlags: flags,
      deleteFlag: (flag) => {
        setFlags((currentValue) => {
          const nextFlags = { ...currentValue };
          delete nextFlags[flag];
          console.log("Flags:", nextFlags);
          return nextFlags;
        });
      },
      reset: () => {
        setFlags(defaultFlags);
        console.log("Flags:", defaultFlags);
      },
      setFlag: (flag, value) => {
        setFlags((currentValue) => {
          const nextFlags = { ...currentValue, [flag]: value };
          console.log("Flags:", nextFlags);
          return nextFlags;
        });
      },
    };
    setInitialized(true);
  }, [setFlags, flags, defaultFlags]);

  const getFeatureFlag = (flagName) => {
    return flags[flagName];
  };

  return {
    flags: {
      flags: Object.keys(flags),
      variants: flags,
    },
    initialized,
    getFeatureFlag,
  };
};
