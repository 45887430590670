const setLocalStorage = (key, data, localStorageClient = undefined) => {
  try {
    localStorageClient = localStorageClient ?? localStorage;

    localStorageClient.setItem(key, JSON.stringify(data));
  } catch (err) {
    console.debug(`Failed to set ${key} in local storage: `, err);
  }
};

const getAndRemoveFromLocalStorage = (key, localStorageClient = undefined) => {
  localStorageClient = localStorageClient ?? localStorage;

  const data = getFromLocalStorage(key, localStorageClient);
  if (typeof data !== "undefined")
    removeFromLocalStorage(key, localStorageClient);

  return data;
};

const getFromLocalStorage = (key, localStorageClient = undefined) => {
  try {
    localStorageClient = localStorageClient ?? localStorage;

    const storeValue = localStorageClient.getItem(key);

    return storeValue === null
      ? undefined
      : JSON.parse(localStorageClient.getItem(key));
  } catch (err) {
    return undefined;
  }
};

const removeFromLocalStorage = (key, localStorageClient = undefined) => {
  try {
    localStorageClient = localStorageClient ?? localStorage;

    localStorageClient.removeItem(key);
  } catch (err) {
    console.debug(`Failed to remove ${key} from local storage: `, err);
  }
};

export {
  setLocalStorage,
  getFromLocalStorage,
  getAndRemoveFromLocalStorage,
  removeFromLocalStorage,
};
