import clsx from "clsx";
import React from "react";
import Image from "src/components/Image";
import Markdown from "src/components/Markdown";

import { Box, Typography, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: "0 auto",
      paddingBottom: theme.spacing(1),

      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
    },
    rootHorizontal: {
      maxWidth: theme.breakpoints.values.md * 0.8,

      [theme.breakpoints.up("lg")]: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
      },
    },
    imageHorizontalOrientation: {
      marginRight: theme.spacing(2),
    },
    imageVerticalOrientation: {
      marginBottom: theme.spacing(2),
    },
    imageDefault: {
      position: "relative",
      height: 96,
      width: 180,
    },
    imageCircle: {
      position: "relative",
      height: 125,
      width: 125,
      borderRadius: "100%",
      overflow: "hidden",
    },
  })
);

const SalesPoint = ({ salesPoint, orientation }) => {
  const classes = useStyles();

  const imageStyle = salesPoint.imageStyle || "Default";

  return (
    <Box
      className={clsx(classes.root, {
        [classes.rootHorizontal]: orientation === "Horizontal",
      })}
      display="flex"
      flexDirection={orientation === "Vertical" ? "column" : "row"}
      alignItems="center"
    >
      <Box
        className={clsx({
          [classes.imageHorizontalOrientation]: orientation === "Horizontal",
          [classes.imageVerticalOrientation]: orientation === "Vertical",
          [classes.imageDefault]: imageStyle === "Default",
          [classes.imageCircle]: imageStyle === "Circle",
        })}
        flexShrink={0}
      >
        <Image
          src={salesPoint.image.url}
          alt={salesPoint.title}
          layout="fill"
          objectFit={imageStyle === "Circle" ? "cover" : "contain"}
        />
      </Box>
      <Box>
        {salesPoint.title && (
          <Typography
            variant="h4"
            align={orientation === "Vertical" ? "center" : "left"}
            gutterBottom
          >
            {salesPoint.title}
          </Typography>
        )}
        {salesPoint.subtitle && (
          <Typography
            component="div"
            variant="body2"
            align={orientation === "Vertical" ? "center" : "left"}
          >
            <Markdown text={salesPoint.subtitle} />
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SalesPoint;
