import { makeStyles } from "@material-ui/core";

/*

footer is just a column-oriented flexbox up until md screen size,
then switches to a grid layout.

*/

export const useStyles = makeStyles((theme) => ({
  salesPointsBackground: {
    background: theme.palette.common.white,
  },

  footerBackground: {
    color: "white",
    background: "#2A2D2C",
  },

  footerLayout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridColumnGap: theme.spacing(2),
      gridRowGap: theme.spacing(8),
    },

    [theme.breakpoints.up("lg")]: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridColumnGap: theme.spacing(3),
    },
  },

  bcorpPosition: {
    width: "100%",
    marginBottom: theme.spacing(10),

    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 8",
      alignSelf: "start",
      marginBottom: 0,
    },

    [theme.breakpoints.up("lg")]: {
      gridColumn: "1 / span 5",
    },

    [theme.breakpoints.up("xl")]: {
      gridColumn: "1 / span 4",
    },
  },

  financialProtectionPosition: {
    width: "100%",
    marginBottom: theme.spacing(10),

    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 8",
      alignSelf: "start",
      marginBottom: 0,
    },

    [theme.breakpoints.up("lg")]: {
      gridColumn: "1 / span 5",
    },

    [theme.breakpoints.up("xl")]: {
      gridColumn: "1 / span 4",
    },
  },

  menuItemsPosition: {
    width: "100%",
    marginBottom: theme.spacing(10),

    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / -1",
      marginBottom: 0,
    },

    [theme.breakpoints.up("lg")]: {
      gridColumn: "7 / span 6",
      gridRow: "1 / span 2",
    },
  },

  copyrightPosition: {
    width: "100%",
    marginBottom: theme.spacing(6),

    [theme.breakpoints.up("md")]: {
      gridColumn: "1 / span 9",
      alignSelf: "center",
      marginBottom: 0,
    },
  },

  socialsPosition: {
    width: "100%",
    textAlign: "center",

    [theme.breakpoints.up("md")]: {
      gridColumn: "10 / -1",
      alignSelf: "center",
      justifySelf: "end",
      textAlign: "right",
    },
  },

  footerContainer: {
    paddingTop: "80px",
  },
}));
