import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@material-ui/core";

import { toggleI18Settings } from "../../state/actions/i18nSettings";

const I18nSettingsLauncher = () => {
  const dispatch = useDispatch();
  const { countryOfResidence } = useSelector((state) => state.user);
  const { userCurrencyCode } = useSelector((state) => state.fx);
  return (
    <Button
      color="inherit"
      style={{ color: "white", borderColor: "white" }}
      variant="outlined"
      onClick={() => {
        dispatch(toggleI18Settings);
      }}
    >
      {countryOfResidence} / {userCurrencyCode}
    </Button>
  );
};

export default I18nSettingsLauncher;
