import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUser } from "src/state/selectors";

export const trackAnalyticsIdentity = (
  userId,
  fullName,
  email,
  createdAt,
  intercomUserHash
) => {
  try {
    if (window.analytics) {
      window.analytics.identify(
        userId,
        {
          name: fullName,
          email,
          user_id: userId,
          created_at: createdAt,
        },
        {
          Intercom: {
            user_hash: intercomUserHash,
          },
        }
      );
    }
  } catch (err) {
    console.error("An error occured whilst trying to track identity: ", err);
  }
};

export const useTrackIdentity = () => {
  const user = useSelector(getUser);

  useEffect(() => {
    if (user.id) {
      trackAnalyticsIdentity(
        user.id,
        `${user.first_name || ""} ${user.last_name || ""}`,
        user.email,
        user.createdAt || undefined,
        user.intercom_user_hash
      );
    }
  }, [
    user.id,
    user.first_name,
    user.last_name,
    user.email,
    user.createdAt,
    user.intercom_user_hash,
  ]);
};
