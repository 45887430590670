import React from "react";
import ReactMarkdown from "react-markdown";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  markeddown: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.primary,
    "& *": { margin: 0 },
    "& p, & ul, & ol": {
      ...theme.typography.body1,
      marginBottom: 16,
    },
    "& h1": {
      ...theme.typography.h1,
      marginBottom: 16,
    },
    "& h2": {
      ...theme.typography.h2,
      marginBottom: 16,
    },
    "& h3": {
      ...theme.typography.h3,
      marginBottom: 16,
    },
    "& h4": {
      ...theme.typography.h4,
      marginBottom: 16,
    },
    "& h5": {
      ...theme.typography.h5,
      marginBottom: 16,
    },
    "& h6": {
      ...theme.typography.h6,
      marginBottom: 16,
    },
    "& a, & a code": {
      // Style taken from the Link component
      color: theme.palette.secondary.main,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    "& img": {
      maxWidth: "100%",
    },
    "& hr": {
      border: 0,
      borderBottom: "1px SOLID #DDD",
      marginBottom: 16,
    },
  },
  markeddownSerif: {
    "& p, & ul, & ol": {
      ...theme.typography.body2,
      marginBottom: 16,
    },
  },
});

const Markdown = ({ classes, text, serif = true, ...other }) => (
  <div
    className={`${classes.markeddown} ${serif ? classes.markeddownSerif : ""}`}
    {...other}
  >
    <ReactMarkdown>{text}</ReactMarkdown>
  </div>
);

export default withStyles(styles)(Markdown);
