import { createContext, useContext } from "react";

import { useFeatureHighlightsPersistentState } from "./state";

const FeatureHighlightsContext = createContext({});

const FeatureHighlightsContextProvider = ({
  children,
  initialState = {},
  store,
}) => {
  const { setFeatureHighlight, featureHighlights } =
    useFeatureHighlightsPersistentState(initialState, store);

  return (
    <FeatureHighlightsContext.Provider
      value={{ setFeatureHighlight, featureHighlights }}
    >
      {children}
    </FeatureHighlightsContext.Provider>
  );
};

const useFeatureHighlightsContext = () => {
  const featureHighlightsContext = useContext(FeatureHighlightsContext);

  return featureHighlightsContext;
};

export { FeatureHighlightsContextProvider, useFeatureHighlightsContext };
