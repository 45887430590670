import axios from "../../utils/axiosinstance";

const fetchUserCurrency = () => ({
  type: "FETCH_USER_CURRENCY",
  payload: axios.get("/api/v3/accounts/profile/currency/"),
});

const setUserCurrency = (currencyCode) => (dispatch, getState) => {
  const { rates } = getState().fx;
  if (Object.prototype.hasOwnProperty.call(rates, currencyCode)) {
    dispatch({
      type: "OPTIMISTICALLY_SET_USER_CURRENCY",
      payload: currencyCode,
    });

    dispatch({
      type: "SET_USER_CURRENCY",
      payload: axios.post("/api/v3/accounts/profile/currency/", {
        code: currencyCode,
      }),
    });
  } else {
    dispatch({ type: "NOOP" });
  }
};

const fetchFxRates = () => ({
  type: "FETCH_FX_RATES",
  payload: axios.get("/api/v2/forex/rates/"),
});

export { fetchUserCurrency, setUserCurrency, fetchFxRates };
