import { getFromLocalStorage, setLocalStorage } from "src/utils/localStorage";

const setInStore = (feature, shouldHighlight) => {
  setLocalStorage(makeLocalStoreKey(feature), shouldHighlight);
};

const getFromStore = (features = []) => {
  return features.reduce((acc, feature) => {
    const storeValue = getFromLocalStorage(makeLocalStoreKey(feature));

    acc[feature] = storeValue;
    return acc;
  }, {});
};

const makeLocalStoreKey = (feature) => `mba_featureHighlight_${feature}`;

export { setInStore, getFromStore };
