import { Box, Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import { useStyles } from "./styles";

export const Mission = ({ title, text }) => {
  const classes = useStyles();
  return (
    <Box
      height={248}
      paddingTop={8}
      paddingX={2}
      textAlign="center"
      bgcolor="white"
      style={{
        backgroundImage: `url('/images/_next/bkg-mountain-tile.png')`,
      }}
    >
      <Container className={classes.purposeContainer}>
        <Typography variant="h5" className={classes.purposeTitle}>
          {title}
        </Typography>
        <Typography className={classes.purposeText}>{text}</Typography>
      </Container>
    </Box>
  );
};
