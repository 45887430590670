function reducer(
  state = {
    loading: false,
    error: false,
    data: [],
    minAdultPrice: 0,
    minChildPrice: 0,
  },
  action
) {
  switch (action.type) {
    case "FETCH_SOCIAL_TRIPS_PENDING":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "FETCH_SOCIAL_TRIPS_FULFILLED": {
      const {
        data: { trips },
      } = action.payload;
      return {
        data: trips ?? [],
        minAdultPromoPrice: trips?.length
          ? trips.reduce(
              (fromPrice, trip) => Math.min(fromPrice, trip.adultPromoPrice),
              Infinity
            )
          : null,
        minChildPromoPrice: trips?.length
          ? trips.reduce(
              (fromPrice, trip) => Math.min(fromPrice, trip.childPromoPrice),
              Infinity
            )
          : null,
        minAdultPrice: trips?.length
          ? trips.reduce(
              (fromPrice, trip) => Math.min(fromPrice, trip.adultPrice),
              Infinity
            )
          : null,
        minChildPrice: trips?.length
          ? trips.reduce(
              (fromPrice, trip) => Math.min(fromPrice, trip.childPrice),
              Infinity
            )
          : null,
        loading: false,
        error: false,
      };
    }
    case "FETCH_SOCIAL_TRIPS_REJECTED":
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
}

export default reducer;
