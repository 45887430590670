import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { MenuItem, TextField } from "@material-ui/core";

import { setUserCurrency } from "../../state/actions";

function CurrencyTextField() {
  const dispatch = useDispatch();

  const { userCurrencyCode, allowedCurrencies } = useSelector(
    (state) => state.fx
  );

  const handleChange = (event) => {
    dispatch(setUserCurrency(event.target.value));
  };

  return (
    <TextField
      label="Preferred currency"
      select
      onChange={handleChange}
      fullWidth
      value={userCurrencyCode}
      margin="normal"
      variant="outlined"
    >
      {allowedCurrencies.map((code) => (
        <MenuItem key={code} value={code}>
          {code}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default CurrencyTextField;
