import axios from "../../utils/axiosinstance";

const fetchCurrentUser = () => ({
  type: "FETCH_CURRENT_USER",
  payload: axios.get("/api/v3/accounts/user/logged_in/"),
});

const fetchUserCountryOfResidence = () => ({
  type: "FETCH_USER_COUNTRY_OF_RESIDENCE",
  payload: axios.get("/api/v3/accounts/profile/country_of_residence/"),
});

const setUserCountryOfResidence = (countryCode) => (dispatch) => {
  dispatch({
    type: "OPTIMISTICALLY_SET_USER_COUNTRY_OF_RESIDENCE",
    payload: countryCode,
  });

  dispatch({
    type: "SET_USER_COUNTRY_OF_RESIDENCE",
    payload: axios.post("/api/v3/accounts/profile/country_of_residence/", {
      country_of_residence: countryCode,
    }),
  });
};

export {
  fetchCurrentUser,
  fetchUserCountryOfResidence,
  setUserCountryOfResidence,
};
