import NextImage from "next/image";
import React from "react";

const Image = ({ src, ...rest }) => (
  <NextImage
    src={
      src?.replace("downloads.ctfassets", "images.ctfassets") ??
      "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
    }
    loading="lazy"
    className="next-image"
    {...rest}
  />
);
export default Image;
