function reducer(
  state = {
    loading: false,
    error: false,
    data: [],
  },
  action
) {
  switch (action.type) {
    case "FETCH_PRICED_OPTIONAL_EXTRAS_PENDING":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "FETCH_PRICED_OPTIONAL_EXTRAS_FULFILLED": {
      const { data } = action.payload;
      return {
        data,
        loading: false,
        error: false,
      };
    }
    case "FETCH_PRICED_OPTIONAL_EXTRAS_REJECTED":
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
}

export default reducer;
