import { useCallback, useState } from "react";

const useFeatureHighlightsPersistentState = (initialState, store) => {
  const [featureHighlights, setFeatureHighlightsState] = useState(initialState);

  const setFeatureHighlight = useCallback(
    (feature, shouldHighlight) => {
      store.setInStore(feature, shouldHighlight);

      setFeatureHighlightsState((state) => ({
        ...state,
        [feature]: shouldHighlight,
      }));
    },
    [store]
  );

  return { featureHighlights, setFeatureHighlight };
};

export { useFeatureHighlightsPersistentState };
