function i18Settings(state = { open: false }, action) {
  switch (action.type) {
    case "TOGGLE_I18N_SETTINGS":
      return {
        open: !state.open,
      };

    default:
      return state;
  }
}

export default i18Settings;
