function reducer(
  state = {
    loading: false,
    rates: null,
    userCurrencyCode: "GBP",
    allowedCurrencies: (
      process.env.NEXT_PUBLIC_ALLOWED_CURRENCIES || "EUR,GBP,USD"
    ).split(","),
  },
  action
) {
  switch (action.type) {
    case "OPTIMISTICALLY_SET_USER_CURRENCY":
      return {
        ...state,
        userCurrencyCode: action.payload,
      };
    case "SET_USER_CURRENCY_PENDING":
    case "FETCH_USER_CURRENCY_PENDING":
      return {
        ...state,
        loading: true,
      };
    case "SET_USER_CURRENCY_FULFILLED":
    case "FETCH_USER_CURRENCY_FULFILLED":
      return {
        ...state,
        loading: false,
        userCurrencyCode: action.payload.data.code,
      };
    case "FETCH_FX_RATES_PENDING":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_FX_RATES_FULFILLED":
      return {
        ...state,
        loading: false,
        rates: action.payload.data,
      };

    default:
      return state;
  }
}

export default reducer;
