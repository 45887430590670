function previewMode(state = false, action) {
  switch (action.type) {
    case "SET_PREVIEW_MODE":
      return action.payload;

    default:
      return state;
  }
}

export default previewMode;
