const makeMenuData = (knowledgeBaseUrl) => ({
  menu: [
    {
      title: "Activities",
      icon: "ChevronRight",
      items: [
        {
          title: "Hiking",
          url: "/explore/hiking/",
          icon: "Hiking",
        },
        {
          title: "Kayaking",
          url: "/explore/kayaking/",
          icon: "Kayaking",
        },
        {
          title: "Mountain Climbing",
          url: "/explore/mountain-climbing/",
          icon: "Ascend",
        },
        {
          title: "Cycling",
          url: "/explore/cycling/",
          icon: "Bike",
        },
        {
          title: "Multi-Activity",
          url: "/explore/multi-activity/",
          icon: "Shaka",
        },
        {
          title: "All Activities",
          url: "/explore/",
          icon: "ArrowRight",
        },
      ],
    },
    {
      title: "Collections",
      icon: "ChevronRight",
      items: [
        {
          title: "Epic Weekends",
          url: "/explore/epic-weekends/",
          icon: "Shaka",
        },
        {
          title: "Big Adventures",
          url: "/explore/big-adventures/",
          icon: "Earth",
        },
        {
          title: "Expeditions",
          url: "/explore/expeditions/",
          icon: "WildCamping",
        },
        {
          title: "New for 2023",
          url: "/explore/new-for-2023/",
          icon: "StarOutline",
        },
        {
          title: "Last Minute",
          url: "/explore/last-minute/",
          icon: "Clock",
        },
        {
          title: "All Collections",
          url: "/explore/",
          icon: "ArrowRight",
        },
      ],
    },
    {
      title: "Destinations",
      icon: "ChevronRight",
      items: [
        {
          title: "Europe",
          url: "/explore/europe/",
          icon: "Earth",
        },
        {
          title: "Asia",
          url: "/explore/asia/",
          icon: "Earth",
        },
        {
          title: "Africa",
          url: "/explore/africa/",
          icon: "Earth",
        },
        {
          title: "South America",
          url: "/explore/south-america/",
          icon: "Earth",
        },
        {
          title: "North America",
          url: "/explore/north-america/",
          icon: "Earth",
        },
        {
          title: "Central America",
          url: "/explore/central-america/",
          icon: "Earth",
        },
      ],
    },
    {
      title: "Other Things",
      icon: "ChevronRight",
      items: [
        {
          title: "Gift Cards",
          url: "/giftcards/",
          icon: "Gift",
        },
        {
          title: "Groups & Corporate",
          url: "/explore/private-group-adventures/",
          icon: "Suitcase",
        },
        {
          title: "Magazine",
          url: "/magazine/",
          icon: "Document",
        },
        {
          title: "Newsletter",
          url: "https://hub.muchbetteradventures.com/subscribe",
          icon: "Message",
        },
      ],
    },
    {
      title: "Mission",
      icon: "ChevronRight",
      items: [
        { title: "About", url: "/about/", icon: "Tree" },
        {
          title: "Sustainability",
          url: "/about/sustainability/",
          icon: "Earth",
        },
        {
          title: "Community",
          url: "/about/our-community/",
          icon: "HeartOutline",
        },
        {
          title: "Book With Confidence",
          url: "https://help.muchbetteradventures.com/book-with-confidence",
          icon: "LockClosed",
        },
      ],
    },
    {
      title: "Contact",
      icon: "ChevronRight",
      items: [
        {
          title: "+1 844-463-9101",
          url: "tel:0018444639101",
          icon: "Phone",
        },
        {
          title: "+44 (0)20 3966 7597",
          url: "tel:00442039667597",
          icon: "Phone",
        },
        {
          title: "Mon–Thu: 9am–7pm GMT\nFri-Sat: 9am–5pm GMT",
          url: "tel:00442039667597",
          icon: "Clock",
        },
        {
          title: "Email Us",
          url: "mailto:hello@muchbetteradventures.com",
          icon: "Message",
        },
        {
          title: "Help",
          url: knowledgeBaseUrl,
          icon: "Help",
        },
      ],
    },
  ],
});

export default makeMenuData;
