import Image from "src/components/Image";

import { Typography } from "@material-ui/core";

import { useStyles } from "./styles";

export const FinancialProtection = ({ title, body, logos }) => {
  const classes = useStyles();
  return (
    <div className={classes.financialProtection}>
      <Typography variant="h4">{title}</Typography>

      {!!body && (
        <Typography className={classes.body} variant="caption" component="div">
          {body}
        </Typography>
      )}

      {!!logos.length && (
        <div className={classes.logos}>
          {logos.map((logo) => (
            <a key={logo.src} href={logo.href} target="_blank" rel="noreferrer">
              <Image
                src={logo.src}
                height={logo.height}
                width={logo.width}
                objectFit="contain"
                alt={logo.alt}
              />
            </a>
          ))}
        </div>
      )}
    </div>
  );
};
