import React, { useContext } from "react";

const FeatureFlagsContext = React.createContext({});

const FeatureFlagProvider = ({ defaultFlags, flagSource, children }) => {
  return (
    <FeatureFlagsContext.Provider value={flagSource(defaultFlags)}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const useFeatureFlagContext = () => {
  const featureFlagContext = useContext(FeatureFlagsContext);

  return featureFlagContext;
};

const IfFeature = ({ flagName, targetValue = null, children }) => (
  <FeatureSwitch flagName={flagName} targetValue={targetValue}>
    {children}
  </FeatureSwitch>
);

const NotFeature = ({ flagName, targetValue = null, children }) => (
  <FeatureSwitch flagName={flagName} targetValue={targetValue} inverse>
    {children}
  </FeatureSwitch>
);

const FeatureSwitch = ({
  flagName,
  targetValue = null,
  inverse = false,
  children,
}) => (
  <FeatureFlagsContext.Consumer>
    {({ getFeatureFlag }) => {
      const enabledOutput = inverse ? null : children;
      const disabledOutput = inverse ? children : null;
      const flagValue = getFeatureFlag(flagName);

      if (targetValue === null) {
        return Boolean(flagValue) ? enabledOutput : disabledOutput;
      }
      return flagValue === targetValue ? enabledOutput : disabledOutput;
    }}
  </FeatureFlagsContext.Consumer>
);

export {
  FeatureFlagProvider,
  useFeatureFlagContext,
  IfFeature,
  NotFeature,
  FeatureSwitch,
};
