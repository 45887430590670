import I18nSettingsLauncher from "src/components/I18nSettingsDialog/I18nSettingsLauncher";

import { Box, Grid, Typography } from "@material-ui/core";

import { useStyles } from "./styles";

// TODO: item title shouldnt be an anchor tag if href is undefined - bad for accessibility

export const MenuItems = ({ menuItemBlocks }) => {
  const classes = useStyles();

  return (
    <Box>
      <Grid item container>
        {menuItemBlocks.map((item) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={item.title}
            className={classes.menuItemBlock}
          >
            <Typography variant="h5">
              <a className={classes.footerListHeader} href={item.href}>
                {item.title}
              </a>
            </Typography>

            <ul className={classes.footerList}>
              {item.items.map((childItem) => (
                <Typography component="li" key={childItem.title}>
                  <a className={classes.footerLink} href={childItem.href}>
                    {childItem.title}
                  </a>
                </Typography>
              ))}
            </ul>

            {item.title === "Partners" && <I18nSettingsLauncher />}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
