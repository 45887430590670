import axios from "../../utils/axiosinstance";

const fetchShortlists = (userId) => ({
  type: "FETCH_SHORTLISTS",
  payload: axios.get(`/api/v3/shortlists/shortlist/?user=${userId}`),
});

const fetchShortlistProducts = (shortlistId) => ({
  type: "FETCH_SHORTLIST_PRODUCTS",
  payload: axios.get(
    `/api/v3/shortlists/shortlist_product/?shortlist=${shortlistId}`
  ),
});

const createShortlist = () => ({
  type: "CREATE_SHORTLIST",
  payload: axios.post("/api/v3/shortlists/shortlist/", {
    name: "My Shortlist",
  }),
});

const addProductToShortlist = (productId, shortlistId) => {
  const data = {
    product: productId,
    shortlist: shortlistId,
  };
  return {
    type: "ADD_PRODUCT_TO_SHORTLIST",
    payload: axios.post(`/api/v3/shortlists/shortlist_product/`, data),
  };
};

const removeProductFromShortlist = (shortlistProduct) => {
  return {
    type: "REMOVE_PRODUCT_FROM_SHORTLIST",
    payload: axios.delete(
      `/api/v3/shortlists/shortlist_product/${shortlistProduct.id}/`
    ),
  };
};

export {
  fetchShortlists,
  fetchShortlistProducts,
  addProductToShortlist,
  removeProductFromShortlist,
  createShortlist,
};
