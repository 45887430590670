import { useEffect, useState } from "react";

export const usePosthog = (defaultFlags = {}) => {
  const [initialized, setInitialized] = useState(false);
  const [flags, setFlags] = useState({
    flags: Object.keys(defaultFlags),
    variants: defaultFlags,
  });

  useEffect(() => {
    if (!initialized) {
      const setupPosthogFlagListener = () => {
        window.posthog.onFeatureFlags((flags, variants) => {
          setInitialized(true);
          setFlags({
            flags,
            variants,
          });
        });
      };

      if (window.posthog && window.posthog.onFeatureFlags) {
        setupPosthogFlagListener();
        return;
      } else {
        window.addEventListener("trackingReady", () => {
          setupPosthogFlagListener();
        });
        return () => {
          window.removeEventListener("trackingReady", setupPosthogFlagListener);
        };
      }
    }
  }, [defaultFlags, initialized]);

  const [getFeatureFlag, setGetFeatureFlag] = useState(() => () => {});

  const _getFeatureFlag = (flagName, sendEvent = true) => {
    return window.posthog.getFeatureFlag(flagName, { send_event: sendEvent });
  };

  useEffect(() => {
    const setupFeatureFlagGetter = () => {
      setGetFeatureFlag(() => _getFeatureFlag);
    };
    if (window.posthog && window.posthog.getFeatureFlag) {
      setupFeatureFlagGetter();
    } else {
      window.addEventListener("trackingReady", () => {
        setupFeatureFlagGetter();
      });
      return () => {
        window.removeEventListener("trackingReady", setupFeatureFlagGetter);
      };
    }
  }, []);

  return { flags, initialized, getFeatureFlag };
};
