import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  purposeTtile: {
    backgroundPositionY: "top",
    backgroundRepeat: "repeat-x",
    "&>*": {
      maxWidth: 500,
      margin: "0 auto",
    },
    color: "#000000",
  },
  purposeText: {
    ...theme.typography.highlight,
    color: theme.palette.text.primary,
  },
  purposeContainer: {
    paddingTop: "30px",
  },
}));
