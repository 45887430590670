import React from "react";
import { useSelector } from "react-redux";

import FX from "./utils/FX";

if (typeof window !== "undefined" && !window.fx) {
  window.fx = new FX();
}

const Forex = () => {
  const { rates, userCurrencyCode } = useSelector((state) => state.fx);
  React.useEffect(() => {
    if (rates !== {}) {
      window.fx.init(userCurrencyCode, rates);
    }
  }, [rates, userCurrencyCode]);

  React.useEffect(() => {
    window.fx.setUserCurrencyCode(userCurrencyCode);
  }, [userCurrencyCode]);

  return null;
};

export default Forex;
