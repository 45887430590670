// ensure that regeneratorRuntime is present *before* loading in components
import "regenerator-runtime/runtime";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/700.css";
import "@fontsource/rubik/900.css";
import "@fontsource/domine/400.css";
import "@fontsource/domine/700.css";

import "./_app.css";

import NextNprogress from "nextjs-progressbar";
import React from "react";
import { useDispatch } from "react-redux";
import AppProviders from "src/context/AppProviders";
import Tracking from "src/tracking";
import { useTrackIdentity, useTrackPage } from "src/utils/track";

import { Box } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import AuthForm from "@muchbetteradventures/signup-form/dist/containers/AuthFormContainer";

import Forex from "../Forex";
import PromoBanner from "../components/Banner";
import Footer from "../components/Footer";
import I18nSettingsDialog from "../components/I18nSettingsDialog";
import MenuBar from "../components/MenuBar";
import { init } from "../state/app";
import wrapper from "../state/createStore";

function MyApp(props) {
  const { Component, pageProps } = props;
  const { footer, promoBanner } = pageProps;

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const dispatch = useDispatch();
  React.useEffect(() => {
    init(dispatch, pageProps.preview);
  }, [dispatch, pageProps.preview]);

  useTrackIdentity();
  useTrackPage();

  return (
    <AppProviders>
      <CssBaseline />
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Box flexGrow={1}>
          <NextNprogress color="#98BE42" />

          <MenuBar />

          <div id="main-content">
            <PromoBanner {...promoBanner} />
            <Component {...pageProps} />
          </div>
        </Box>

        {!!footer && (
          <Box alignContent="flex-end">
            <div id="footer-content">
              <Footer {...footer} />
            </div>
          </Box>
        )}
      </Box>
      <AuthForm />
      <I18nSettingsDialog />
      <Forex />
      <Tracking />
    </AppProviders>
  );
}

export default wrapper.withRedux(MyApp);
