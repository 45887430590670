const initialState = {};
function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case "SET_REVIEWS_INITIAL_STATE":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

export default reducer;
