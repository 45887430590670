import {
  fetchCurrentUser,
  fetchFxRates,
  fetchShortlistProducts,
  fetchShortlists,
  fetchUserCountryOfResidence,
  fetchUserCurrency,
} from "./actions";

function init(dispatch, previewMode) {
  dispatch({ type: "SET_PREVIEW_MODE", payload: previewMode ? true : false });
  dispatch(fetchFxRates());
  dispatch(fetchUserCurrency());
  dispatch(fetchUserCountryOfResidence());
  dispatch(fetchCurrentUser())
    .then(({ action }) => {
      // eslint-disable-next-line no-unused-expressions
      if (action.payload.data.user) {
        return dispatch(fetchShortlists(action.payload.data.user.id));
      }
      return Promise.reject();
    })
    .then(({ action }) =>
      Promise.all(
        action.payload.data.results.map((result) =>
          dispatch(fetchShortlistProducts(result.id))
        )
      )
    )
    .catch(() => {});
}

export { init };
