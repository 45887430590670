function reducer(
  state = {
    open: false,
    type: "social",
  },
  action
) {
  switch (action.type) {
    case "DATES_AND_PRICES_HIDE":
      return {
        ...state,
        open: false,
      };
    case "DATES_AND_PRICES_SHOW": {
      return {
        open: true,
        type: action.payload || "social",
      };
    }
    default:
      return state;
  }
}

export default reducer;
