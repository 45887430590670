import {} from "@material-ui/core";
import {} from "@material-ui/core";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

import { setUserCountryOfResidence } from "../../state/actions";
import { toggleI18Settings } from "../../state/actions/i18nSettings";
import CountryAutocomplete from "../LocationAutocomplete/CountryAutocomplete";
import CurrencyTextField from "./CurrencyTextField";

const I18nSettingsDialog = () => {
  const dispatch = useDispatch();

  const { countryOfResidence } = useSelector((state) => state.user);
  const { open } = useSelector((state) => state.i18nSettings);
  function handleClose() {
    dispatch(toggleI18Settings);
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle disableTypography>
        <Typography variant="h4">Location settings</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          We will tailor the site and display prices based on these settings.
        </Typography>
        <CountryAutocomplete
          value={countryOfResidence}
          label="Your location"
          onChange={(countryCode) =>
            dispatch(setUserCountryOfResidence(countryCode))
          }
          hideStartIcon
          TextFieldProps={{
            fullWidth: true,
            margin: "normal",
          }}
        />
        <CurrencyTextField />
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={handleClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default I18nSettingsDialog;
