export const trackAnalyticsEvent = (
  trackEvent,
  trackProps = {},
  callback = null
) => {
  try {
    if (window.analytics && trackEvent) {
      window.analytics.track(trackEvent, trackProps, {}, callback);
    } else if (callback) {
      callback();
    }
  } catch (err) {
    console.error(
      "An error occured whilst trying to track event: ",
      trackEvent,
      err
    );
  }
};
