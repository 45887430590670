// Custom Events

export const EVENT_NAME_BANNER_CLICK = (id, title) =>
  `Banner Click: #${id}: ${title}`;

export const EVENT_NAME_REVIEWS_INTERACTION = "Reviews Interaction";
export const EVENT_NAME_REVIEW_PHOTO_SLIDE_CHANGED =
  "Review Photo Slide Changed";

export const EVENT_NAME_EXPLORE_PAGE_VIEW = "Explore Page View";

export const EVENT_NAME_BREADCRUMB_CLICK = "Breadcrumb Click";

export const EVENT_NAME_CLICK_BOOK_PRIVATE_TRIP = "Clicked Book a Private Trip";

export const EVENT_NAME_CLICK_CAROUSEL_ITEM = "Clicked Carousel Item";

export const EVENT_NAME_CLICK_CHECK_FLIGHTS_LINK = "Check Flights Link Clicked";

export const EVENT_NAME_CLICK_DATES_PRICES = "Clicked Dates & Prices";

export const EVENT_NAME_CLICK_ENQUIRE_NOW = "Clicked Enquire Now";

export const EVENT_NAME_CLICK_FIND_ANOTHER_TRIP = "Clicked Find another trip";

export const EVENT_NAME_CLICK_GIFTCARD = "Clicked Giftcard";

export const EVENT_NAME_CLICK_GRID_ITEM = "Clicked Grid Item";

export const EVENT_NAME_CLICK_PHOTOS = "Clicked Photos";

export const EVENT_NAME_CLICK_SEARCH_MODE_TAB = "Clicked Search Mode Tab";

export const EVENT_NAME_CLICK_SECTION_NAV = "Clicked Section Nav";

export const EVENT_NAME_CLICK_RECOMMENDED_PRODUCT =
  "Clicked Recommended Product";

export const EVENT_NAME_CLICK_TRAVEL_RESTRICTIONS =
  "Clicked Travel Restriction";

export const EVENT_NAME_CLICK_TRIP_DETAIL = "Clicked Trip Detail";

export const EVENT_NAME_CLICK_VIDEO = "Clicked Video";

export const EVENT_NAME_CLICK_VIEW_ALL_TRIPS_BUTTON =
  "Clicked View All Trips Button";

export const EVENT_NAME_EXPLORE_PAGE_CAROUSEL_SLIDE_CHANGE =
  "Explore page carousel slide change";

export const EVENT_NAME_EXPLORE_PAGE_TAB_CHANGE = "Explore page tab change";

export const EVENT_NAME_OPEN_PHOTO_REVIEWS = "Open Photo Reviews";

export const EVENT_NAME_PANEL_EXPANDED = "Panel Expanded";

export const EVENT_NAME_PRODUCT_CARD_IMAGE_CAROUSEL =
  "Product card image carousel";

export const EVENT_NAME_PRODUCT_OVERVIEW_REVIEWS_CLICK =
  "Clicked Product Overview Reviews";

export const EVENT_NAME_REFINE_EXPLORE_PAGE_FILTER =
  "Refined Explore Page Filter";

export const EVENT_NAME_RESET_EXPLORE_PAGE_FILTERS =
  "Reset Explore Page Filters";

export const EVENT_NAME_SEARCH_FILTER_CHANGED = "Search filter changed";

export const EVENT_NAME_VIEW_DEPARTURES_SEARCH_MODE =
  "Viewed Departures Search Mode";

export const EVENT_NAME_VIEW_TRIP_DETAIL = "Viewed Trip Detail";

// Standard ECommerce Events
// @see https://segment.com/docs/connections/spec/ecommerce/v2/#browsing

// Browsing

export const EVENT_NAME_PRODUCT_LIST_VIEWED = "Product List Viewed";

export const EVENT_NAME_PRODUCT_LIST_FILTERED = "Product List Filtered";

// Core Ordering

export const EVENT_NAME_PRODUCT_VIEWED = "Product Viewed";

export const EVENT_NAME_PRODUCT_CLICKED = "Product Clicked";

export const EVENT_NAME_PRODUCT_ADDED = "Product Added";

// Promotions

export const EVENT_NAME_PROMOTION_VIEWED = "Promotion Viewed";

export const EVENT_NAME_PROMOTION_CLICKED = "Promotion Clicked";

// Wishlist

export const EVENT_NAME_PRODUCT_ADDED_TO_WISHLIST = "Product Added to Wishlist";

export const EVENT_NAME_PRODUCT_REMOVED_FROM_WISHLIST =
  "Product Removed from Wishlist";
