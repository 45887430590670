import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  financialProtection: {},

  body: {
    marginTop: theme.spacing(1),
  },

  logos: {
    display: "flex",
    gap: theme.spacing(2),

    marginTop: theme.spacing(3),

    "& > a": {
      cursor: "pointer",
    },
  },
}));
