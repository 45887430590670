import PropTypes from "prop-types";
import { useCallback } from "react";

import { PromoBanner as PromoBannerPresentation } from "@muchbetteradventures/styleguide";

import { trackClick } from "./tracking";

const PromoBanner = ({
  id,
  title,
  subtitle,
  link,
  backgroundColor,
  titleColor,
  subtitleColor,
}) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      trackClick(id, title, link);
    },
    [id, title, link]
  );

  // Make sure nulls are coalesced to undefined
  // so the presentation layer can determine the defaults

  return (
    <PromoBannerPresentation
      title={title}
      subtitle={subtitle}
      link={link}
      backgroundColor={backgroundColor ?? undefined}
      titleColor={titleColor ?? undefined}
      subtitleColor={subtitleColor ?? undefined}
      onClick={handleClick}
    />
  );
};

PromoBanner.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.string,
  backgroundColor: PropTypes.string,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
};

export default PromoBanner;
