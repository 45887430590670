import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  bcorp: {},

  contentLayout: {
    display: "flex",

    marginTop: theme.spacing(1),
  },

  imagePosition: {
    width: "66px",
    marginRight: theme.spacing(2),
    flexShrink: 0,
    paddingTop: theme.spacing(1),
  },

  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  cta: {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
}));
