import { memo } from "react";

import { Container, ThemeProvider } from "@material-ui/core";
import { themeV2Mui } from "@muchbetteradventures/mui-theme";

import { Bcorp } from "./components/Bcorp/Bcorp";
import { Copyright } from "./components/Copyright/Copyright";
import { FinancialProtection } from "./components/FinancialProtection/FinancialProtection";
import { MenuItems } from "./components/MenuItems/MenuItems";
import { Mission } from "./components/Mission/Mission";
import SalesPoints from "./components/SalesPoints/SalesPoints";
import { SocialButtons } from "./components/SocialButtons/SocialButtons";
import { useStyles } from "./styles";

/*
    type MenuItem = 
    {
      id: string
      title: string
      slug: string
      redirectToUrl?: string
    };

    type MenuItemBlock = 
    {
        id: string
        title: string
        slug: string
        redirectToUrl?: string
        items: MenuItem[];
    };

    type Mission = {
        title: string;
        text: string;
    }

    type Bcorp = {
        title: string;
        body: string;
        ctaText: string;
        ctaHref: string;
        logoSrc: string
    }

    type FinancialProtection = {
        title: string;
        body: string;
        logos: {
          src: string
          height: number
          width: number
          href: string
          alt?: string 
        }[]
    }

    type SalesPoints = {
      orientation: "Vertical" | "Horizontal"
      salesPoints: {
        title: string
        subtitle: string
        image: {
          url: string
        },
        imageStyle: "Default" | "Circle"
      }[]
    }

    type FooterProps = {
        menuItemBlocks: MenuItemBlock[];
        mission: Mission;
        bcorp: Bcorp;
        salesPoints: SalesPoints
    }

    {menuItemBlocks, mission, bcorp, socials}: FooterProps

    getFooterBusinessLogicPropsProvider
*/
// {menuItemBlocks, mission, bcorp, socials}
const Footer = ({
  menuItemBlocks,
  mission,
  bcorp,
  financialProtection,
  salesPoints,
}) => {
  const classes = useStyles();

  return (
    <>
      {salesPoints && salesPoints.salesPoints.length && (
        <div className={classes.salesPointsBackground}>
          <Container fixed maxWidth="xl" bgcolor="white">
            <SalesPoints
              orientation={salesPoints.orientation}
              salesPoints={salesPoints.salesPoints}
            />
          </Container>
        </div>
      )}

      {!!mission && <Mission title={mission.title} text={mission.text} />}

      <div className={classes.footerBackground}>
        <Container fixed maxWidth={false} className={classes.footerContainer}>
          <div className={classes.footerLayout}>
            {!!financialProtection && (
              <div className={classes.financialProtectionPosition}>
                <FinancialProtection
                  title={financialProtection.title}
                  body={financialProtection.body}
                  logos={financialProtection.logos}
                />
              </div>
            )}

            {!!bcorp && (
              <div className={classes.bcorpPosition}>
                <Bcorp
                  title={bcorp.title}
                  body={bcorp.body}
                  ctaText={bcorp.ctaText}
                  ctaHref={bcorp.ctaHref}
                  logoSrc={bcorp.logoSrc}
                />
              </div>
            )}

            <div className={classes.menuItemsPosition}>
              <MenuItems menuItemBlocks={menuItemBlocks} />
            </div>

            <div className={classes.copyrightPosition}>
              <Copyright></Copyright>
            </div>

            <div className={classes.socialsPosition}>
              <SocialButtons></SocialButtons>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const FooterThemeV2 = (footerProps) => (
  <ThemeProvider theme={themeV2Mui}>
    <Footer {...footerProps} />
  </ThemeProvider>
);

export default memo(FooterThemeV2);
