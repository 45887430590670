export const FEATURE_FLAG_DEPARTURES_MODE_FEATURE_HIGHLIGHT =
  "departuresModeFeatureHighlight";

export const FEATURE_FLAG_ENABLE_DEPARTURES_MODE = "enableDeparturesMode";
export const FEATURE_FLAG_EXPLORE_PAGE_SEARCH_ONLY = "explorePageSearchOnly";
export const FEATURE_FLAG_EXPLORE_PAGE_WWW = "no-carousel-filter-refinements";

export const CONTROL = "control";
export const TEST = "test";

const getFlagsFromEnv = () => {
  try {
    return JSON.parse(process.env.NEXT_PUBLIC_FEATURE_FLAG_DEFAULT_FLAGS);
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const FLAGS_FROM_ENV = getFlagsFromEnv();
