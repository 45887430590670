import { HYDRATE, createWrapper } from "next-redux-wrapper";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import promiseMiddleware from "redux-promise-middleware";
import thunk from "redux-thunk";

import accommodationCategories from "./reducers/accommodationCategories";
import currentProduct from "./reducers/currentProduct";
import datesAndPricesDisplay from "./reducers/datesAndPricesDisplay";
import fx from "./reducers/fx";
import i18nSettings from "./reducers/i18nSettings";
import previewMode from "./reducers/previewMode";
import pricedOptionalExtras from "./reducers/pricedOptionalExtras";
import privateGroupPricingPeriods from "./reducers/privateGroupPricingPeriods";
import resourceSet from "./reducers/resourceSet";
import reviews from "./reducers/reviews";
import search from "./reducers/search";
import shortlist from "./reducers/shortlist";
import socialTrips from "./reducers/socialTrips";
import tripDetail from "./reducers/tripDetail";
import universalProduct from "./reducers/universalProduct";
import user from "./reducers/user";

const defaultState = {
  user: { countryOfResidence: "GB" },
  shortlist: { id: null, products: [], shortlists: [] },
  i18nSettings: { open: false },
  previewMode: false,
  search: {},
};

const rootReducer = combineReducers({
  accommodationCategories,
  currentProduct,
  datesAndPricesDisplay,
  reviews,
  fx,
  i18nSettings,
  previewMode,
  pricedOptionalExtras,
  privateGroupPricingPeriods,
  resourceSet,
  search,
  shortlist,
  socialTrips,
  tripDetail,
  universalProduct,
  user,
});

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload,
      user: { ...state.user },
      shortlist: { ...state.shortlist },
      fx: { ...state.fx },
      previewMode: state.previewMode,
    };
  }
  return rootReducer(state, action);
};

const composeEnhancers =
  typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const middleware = applyMiddleware(promiseMiddleware, thunk);
const enhancer = composeEnhancers(middleware);

const makeStore = (/*context*/) => createStore(reducer, defaultState, enhancer);
const wrapper = createWrapper(makeStore, {
  debug: !process.env.NODE_ENV === "production",
});
export default wrapper;
