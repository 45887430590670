import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";

export const trackAnalyticsPage = () => {
  try {
    if (window.analytics) {
      window.analytics.page();
    }
  } catch (err) {
    console.error("An error occured whilst trying to track page: ", err);
  }
};

export const useTrackPage = () => {
  const router = useRouter();

  const pathWithoutQuery = useMemo(
    () => router.asPath.split("?")[0],
    [router.asPath]
  );

  useEffect(() => {
    trackAnalyticsPage();
  }, [pathWithoutQuery]); // pathWithoutQuery added as dependency to ensure this runs on client side navigation
};
