/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import EventEmitter from "eventemitter3";

class FX extends EventEmitter {
  constructor() {
    super();
    this.ready = false;
  }

  init(userCurrencyCode, fxRates) {
    this.ready = true;
    this.setFxRates(fxRates);
    this._userCurrencyCode = userCurrencyCode;
    this.emit("init", this.getUserCurrencyCode());
    this.emit("change", this.getUserCurrencyCode());
  }

  setUserCurrencyCode(code) {
    this._userCurrencyCode = code;
    this.emit("change", this.getUserCurrencyCode());
  }

  setFxRates(rates) {
    this._fxRates = rates;
  }

  getUserCurrencyCode() {
    return this._userCurrencyCode;
  }

  buy(amount, from, to) {
    return amount / this._fxRates[from][to];
  }

  renderPrice(price, currencyCode, round = false) {
    /*
      converts the price from the currencyCode to the user currency,
      rounds it and formats it using toLocaleString then removes the
      decimal places that toLocaleString puts in.
      */
    if (!this.ready) return "";

    const renderedPrice = Math.round(
      this.buy(price, this.getUserCurrencyCode(), currencyCode)
    ).toLocaleString("en", {
      currency: this.getUserCurrencyCode(),
      style: "currency",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    if (round) {
      return renderedPrice.slice(0, -3);
    }
    return renderedPrice;
  }
}
export default FX;
