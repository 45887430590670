function accommodationCategoriesReducer(state = { entries: [] }, action) {
  switch (action.type) {
    case "SET_ACCOMMODATION_CATEGORIES":
      return {
        ...state,
        entries: action.payload,
      };
    default:
      return state;
  }
}

export default accommodationCategoriesReducer;
