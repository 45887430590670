import { memo } from "react";
import Image from "./Image";

// Wraps image component to add props that specifically target loading detailed photos.

// 'sizes' prop should be set to the max % of the viewport width that the image will take up.
// @see https://nextjs.org/docs/api-reference/next/image#sizes

const Photograph = ({
  src,
  sizes,
  quality = 50,
  alt = "",
  priority = false,
  shouldShowPlaceholder = true,
  ...rest
}) => {
  if (!sizes && process.env.NODE_ENV !== "production")
    console.warn(
      `Photograph component with src ${src} is being rendered without setting the size. This could cause the image to be significantly larger than it should be and damage performance.`
    );

  return (
    <Image
      src={src}
      alt={alt}
      layout="fill"
      objectFit="cover"
      sizes={sizes}
      quality={quality}
      placeholder={priority || !shouldShowPlaceholder ? "empty" : "blur"}
      blurDataURL={greyPixelBase64}
      priority={priority}
      loading={priority ? "eager" : "lazy"}
      {...rest}
    />
  );
};

// just a single pixel png, but base64 encoded so we can use it for the blurDataURL.
// should be around 68 bytes
// @see https://png-pixel.com/
const greyPixelBase64 =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8/R8AArsB3IYZcW4AAAAASUVORK5CYII=";

export default memo(Photograph);
