import humps from "lodash-humps";

const initialState = {
  loading: false,
  error: false,
  tripCart: {},
  trip: {},
  show: false,
};

function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case "SET_SELECTED_TRIP":
      return {
        ...state,
        trip: action.payload,
        show: true,
      };
    case "FETCH_TRIP_CART_PENDING":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "FETCH_TRIP_CART_FULFILLED": {
      return {
        ...state,
        tripCart: humps(action.payload.data),
        loading: false,
        error: false,
      };
    }
    case "FETCH_TRIP_CART_REJECTED":
      return {
        ...state,
        tripCart: {},
        loading: false,
        error: true,
      };
    case "RESET_TRIP_DETAIL":
      return { ...state, show: false };
    default:
      return state;
  }
}

export default reducer;
