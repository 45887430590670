import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  logoMark: {
    marginRight: theme.spacing(),
  },
  logoAndCopyright: {
    display: "flex",
    alignItems: "center",
    color: "#8e8e8e",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignContent: "center",
    },
  },
  copyright: {
    display: "flex",
    color: "#8e8e8e",
    flexDirection: "column",

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
  },
}));
