import React from "react";
import { makeMegaMenuData } from "src/lib/rawData/megaMenu";

import MenuBar from "@muchbetteradventures/menubar/dist/App";

const megaMenuData = makeMegaMenuData(
  process.env.NEXT_PUBLIC_KNOWLEDGE_BASE_URL
);

const MenuBarWithData = (props) => (
  <MenuBar
    activities={megaMenuData.activities || []}
    menu={megaMenuData.menu}
    algoliaAppId={process.env.NEXT_PUBLIC_ALGOLIA_APP_ID}
    algoliaPublicApiKey={process.env.NEXT_PUBLIC_ALGOLIA_PUBLIC_API_KEY}
    algoliaProductIndex={process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX}
    knowledgeBaseUrl={process.env.NEXT_PUBLIC_KNOWLEDGE_BASE_URL}
    {...props}
  />
);

MenuBarWithData.propTypes = MenuBar.propTypes;
MenuBarWithData.defaultProps = MenuBar.defaultProps;

export default MenuBarWithData;
