/* eslint-disable @next/next/no-sync-scripts */
import React from "react";

export default function Tracking() {
  return (
    <script
      src={process.env.NEXT_PUBLIC_TRACKING_SCRIPT_SRC}
      data-segment-write-key={process.env.NEXT_PUBLIC_SEGMENT_API_KEY}
      data-posthog-api-key={process.env.NEXT_PUBLIC_POSTHOG_API_KEY}
      data-plausible-domain={process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAIN}
      type="text/javascript"
    ></script>
  );
}
