function userApp(
  state = { countryOfResidence: "GB", loadingCountryOfResidence: false },
  action
) {
  switch (action.type) {
    case "FETCH_CURRENT_USER_PENDING":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_CURRENT_USER_FULFILLED":
      return {
        ...state,
        loading: false,
        ...action.payload.data.user,
      };
    case "FETCH_USER_COUNTRY_OF_RESIDENCE_PENDING":
      return {
        ...state,
        loadingCountryOfResidence: true,
      };
    case "FETCH_USER_COUNTRY_OF_RESIDENCE_FULFILLED":
      return {
        ...state,
        loadingCountryOfResidence: false,
        countryOfResidence: action.payload.data.country_of_residence,
      };
    case "OPTIMISTICALLY_SET_USER_COUNTRY_OF_RESIDENCE":
      return {
        ...state,
        loadingCountryOfResidence: false,
        countryOfResidence: action.payload,
      };

    default:
      return state;
  }
}

export default userApp;
