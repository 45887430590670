function shortlistApp(state = {}, action) {
  switch (action.type) {
    case "FETCH_SHORTLISTS_PENDING":
      return {
        ...state,
        loading: true,
        products: [],
        shortlists: [],
      };
    case "FETCH_SHORTLISTS_FULFILLED":
      return {
        ...state,
        loading: false,
        products: [],
        shortlists: [...action.payload.data.results],
      };
    case "FETCH_SHORTLIST_PRODUCTS_PENDING":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_SHORTLIST_PRODUCTS_FULFILLED":
      return {
        ...state,
        products: action.payload.data.results,
        loading: false,
      };
    case "SHORTLIST_PRODUCT_PENDING":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_SHORTLIST_PENDING":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_SHORTLIST_FULFILLED":
      return {
        ...state,
        loading: false,
      };
    case "SHORTLIST_PRODUCT_FULFILLED":
      return {
        ...state,
        loading: false,
      };
    case "SHORTLIST_PRODUCT_REJECTED":
    case "FETCH_SHORTLIST_PRODUCTS_REJECTED":
    case "FETCH_SHORTLISTS_REJECTED":
    case "CREATE_SHORTLIST_REJECTED":
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}

export default shortlistApp;
