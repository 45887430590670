const initialState = {
  items: {},
};

function reducer(state = { ...initialState }, action) {
  switch (action.type) {
    case "SET_SHADOW_ITEMS":
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.attribute]: action.payload.items,
        },
      };
    case "SET_INITIAL_ITEMS":
      return {
        ...state,
        initialItems: {
          ...state.initialItems,
          [action.payload.attribute]: action.payload.items,
        },
      };
    default:
      return state;
  }
}

export default reducer;
